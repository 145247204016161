.MeineRow {
  background-color: rgb(206, 206, 206);
}

.Meintext {
  text-align: center;
  font-size: 120%; 
  padding: 10px 0;
  margin: auto;
//  min-width: 360px;
}
  
.Meinbild {
//  min-width: 360px;
  margin: 2%;
}

@media (max-width: 765px) { 
  .Meinbild {
    display: none;
  }
}

.Meinform {
  text-align: center; 
  max-width: 30em;
  margin: auto;
  padding: 2%;
  color: black;
  font-size: 150%;
}

.form-control {
  margin: 0.5% 0;
}
