.inline {
  width: 2.3em;
  height: auto;
  padding: 2px 0;
  opacity: 0.6;
}

.inline:hover {
  opacity: 1;
}

.onimage {
  @extend .inline;
  position: absolute;
  bottom: 5%;
  left: 5%;
}
